/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import Layout from "../components/Layout/Layout"
import Header from "../components/Header/Header"
import BlogPageListing from "../components/BlogPageListing/BlogPageListing"
import BlogPagination from "../components/BlogPagination/BlogPagination"
import Footer from "../components/Footer/Footer"
import SeoPage from "../components/seo-page"

const BlogIndex = ({
  data,
  pageContext: { postsPerPage },
}) => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const seoFields = {
    title: {
      en: "Ensys blog articles and news",
      ro: "Articole și noutăți pe blog Ensys",
    },
    description: {
      en: "Read green and sustainable energy industry articles with case studies, tips, news, technology, and more, only on the Ensys blog.",
      ro: "Citiți articole pentru industria de energie verde și sustenabilă, cu studii de caz, sfaturi, știri, tehnologie, și multe altele, doar pe blog Ensys.",
    },
    keyword: {
      en: "Ensys blog",
      ro: "blog Ensys",
    },
    image: {
      en: "https://wp.ensys.com.ro/wp-content/uploads/2023/03/ENS-OG-IMAGE.png",
      ro: "https://wp.ensys.com.ro/wp-content/uploads/2023/03/ENS-OG-IMAGE.png",
    },
    slug: {
      en: "blog",
      ro: "blog",
    },
  }
  function getSeoFields(field) {
    return seoFields[field][language] ?? seoFields[field].ro // return ro fields if current language does not have them
  }
  const posts = {
    en: data.allWpPostEn,
    ro: data.allWpPostRo,
  }

  const filteredPosts = posts[language].nodes;
  const [activeTab, setActiveTab] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [totalPages, setTotalPages] = useState(Math.ceil(filteredPosts.length / postsPerPage))
  const [startIndex, setStartIndex] = useState(0);
  const firstPage = typeof document !== "undefined" ? document.querySelector('.pagination-component li:nth-child(2) a') : null;

  const handleCategoryChange = (param) => {
    setActiveTab(param);
    setStartIndex(0);
    if (firstPage) {
      firstPage.click();
    }
  }

  const handlePageChange = (param) => {
    if (param >= 0) {
      setStartIndex(param * postsPerPage);
    } else {
      setStartIndex(0);
    }
  }

  useEffect(() => {
    if (filteredPosts && activeTab !== "") {
      const filteredData = filteredPosts.filter(post =>
        post.categories.nodes.some(category => category.name.toLowerCase() === activeTab.toLowerCase())
      );

      setTotalPages(Math.ceil(filteredData.length / postsPerPage));

      if (startIndex + postsPerPage > filteredData.length) {
        const remainingItems = filteredData.length - startIndex;
        const paginatedData = filteredData.slice(startIndex, startIndex + remainingItems);
        setFilteredData(paginatedData);
      } else {
        const paginatedData = filteredData.slice(startIndex, startIndex + postsPerPage);
        setFilteredData(paginatedData);
      }
    } else {
      setTotalPages(Math.ceil(filteredPosts.length / postsPerPage));
      const paginatedData = filteredPosts.slice(startIndex, startIndex + postsPerPage);
      setFilteredData(paginatedData);
    }
  }, [filteredPosts, activeTab, startIndex]);

  if (!filteredPosts.length) {
    return (
      <Layout isHomePage>
        <SeoPage
          title={getSeoFields("title")}
          description={getSeoFields("description")}
          keyword={getSeoFields("keyword")}
          image={getSeoFields("image")}
          slug={getSeoFields("slug")}
        />
        <Header />
        <p style={{ marginTop: "5rem", marginBottom: "10rem" }}>
          No blog posts found.
        </p>
        <Footer />
      </Layout>
    )
  }
  return (
    <Layout isHomePage>
      <SeoPage
        title={getSeoFields("title")}
        description={getSeoFields("description")}
        keyword={getSeoFields("keyword")}
        image={getSeoFields("image")}
        slug={getSeoFields("slug")}
      />
      <Header />
      <BlogPageListing
        data={filteredData}
        active={activeTab}
        handleCategoryChange={handleCategoryChange}
      />
      <BlogPagination
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
      <Footer />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive {
    allWpPostEn: allWpPost(
      sort: { fields: [date], order: DESC }
      filter: { language: { code: { eq: EN } } }
    ) {
      nodes {
        link
        title
        translations {
          link
        }
        categories {
          nodes {
            name
          }
        }
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          opengraphImage {
            link
          }
        }
        postExtraData {
          dataAboutPost {
            url
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
        date(formatString: "DD, MMMM, YYYY")
        categories {
          nodes {
            id
            name
            link
          }
        }
        tags {
          nodes {
            id
            name
            link
          }
        }
      }
    }
    allWpPostRo: allWpPost(
      sort: { fields: [date], order: DESC }
      filter: { language: { code: { eq: RO } } }
    ) {
      nodes {
        link
        title
        categories {
          nodes {
            name
          }
        }
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          opengraphImage {
            link
          }
        }
        postExtraData {
          dataAboutPost {
            url
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
        date(formatString: "DD, MMMM, YYYY")
        categories {
          nodes {
            id
            name
            link
          }
        }
        tags {
          nodes {
            id
            name
            link
          }
        }
      }
    }
  }
`
