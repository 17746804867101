import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

export const useAllCategories = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { allWpCategoryEn, allWpCategoryRo } = useStaticQuery(graphql`
    query MyQuery {
      allWpCategoryRo: allWpCategory(
        filter: { language: { code: { eq: RO } } }
      ) {
        nodes {
          id
          name
          link
          posts {
            nodes {
              id
            }
          }
        }
      }
      allWpCategoryEn: allWpCategory(
        filter: { language: { code: { eq: EN } } }
      ) {
        nodes {
          id
          name
          link
          posts {
            nodes {
              id
            }
          }
        }
      }
    }
  `)

  const categories = {
    en: allWpCategoryEn,
    ro: allWpCategoryRo,
  }
  return categories[language].nodes
}
