import React from "react"
import PropTypes from "prop-types"
import ReactPaginate from "react-paginate"
import "./BlogPagination.scss"

const BlogPagination = ({ totalPages, handlePageChange }) => {

  return (
    <section className="blog-pagination">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="pagination">
              {totalPages && totalPages > 1 && (
                <ReactPaginate
                  pageCount={totalPages}
                  nextLabel=">"
                  previousLabel="<"
                  renderOnZeroPageCount={false}
                  className="pagination-component"
                  onPageChange={(selectedPage) => {
                    handlePageChange(selectedPage.selected)
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
}

export default BlogPagination
