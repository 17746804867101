import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import uniqueId from "lodash/uniqueId"
import { useTranslation } from "react-i18next"
import ArticleItem from "../ArticleItem/ArticleItem"
import "./BlogPageListing.scss"
import CategoriesTabs from "../CategoriesTabs/CategoriesTabs"

const BlogPageListing = ({ data, active, handleCategoryChange }) => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const translations = {
    ro: {
      "Articole și noutăți - ensys": "Articole și noutăți - ensys",
    },
    en: {
      "Articole și noutăți - ensys": "Articles and news - ensys",
    },
  }

  return (
    <section className="blog-posts-listing">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2>{translations[language]["Articole și noutăți - ensys"]}</h2>
            <hr className="divider" />
          </div>
        </div>

        <CategoriesTabs active={active} handleButtonClick={handleCategoryChange} />
        <div className="row">
          {data &&
            data.map(post => (
              <div className="col-md-6 col-lg-4" key={uniqueId("bl_ens_")}>
                <ArticleItem
                  title={post.title}
                  tags={post.tags?.nodes}
                  categories={post.categories?.nodes}
                  date={post.date}
                  link={post.translations && post.translations.length > 0 ? post.translations[0].link : post.link}
                  externalLink={post.postExtraData?.dataAboutPost?.url}
                  image={post.postExtraData?.dataAboutPost?.image}
                  seo={post.seo}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

BlogPageListing.defaultProps = {
  data: [],
  active: "",
}

BlogPageListing.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  active: PropTypes.string,
}
export default BlogPageListing
