import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import uniqueId from "lodash/uniqueId"
import "react-tabs/style/react-tabs.css"
import "./CategoriesTabs.scss"
import { useAllCategories } from "../../hooks/Blog/useAllCategories"
import { useTranslation } from "react-i18next"
import translations from "../../../locales/en/translation.json"

const CategoriesTabs = ({ active, handleButtonClick }) => {
  const { i18n } = useTranslation();
  const { language } = i18n

  const allCategories = useAllCategories().filter(
    cat => cat.posts?.nodes?.length > 0
  )

  const [showList, setShowList] = useState(false)

  const handleListToggle = () => {
    setShowList(!showList)
  }

  return (
    <div className="tabs-container -center">
      <ul className="tab-list">
        <li className="list-toggle" onClick={handleListToggle}>
          {translations["Alegeți o categorie"][language]}
        </li>
        <Link className={`tab ${active ? "" : "active"} ${showList ? "" : "hide-element"}`} to="/articole-ensys/"
          onClick={() => handleButtonClick('')}
        >
          {translations["Toate Categoriile"][language]}
        </Link>
        {allCategories &&
          allCategories.map(item => (
            <Link className={`tab ${item.name === active ? "active" : ""} ${showList ? "" : "hide-element"}`} key={uniqueId("ens_")} to="#"
              onClick={() => handleButtonClick(item.name)}>
              <li>{item.name}</li>
            </Link>
          ))}
      </ul>
    </div>
  )
}

CategoriesTabs.propTypes = {
  active: PropTypes.string.isRequired,
}

export default CategoriesTabs
