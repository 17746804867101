import React from "react"
import PropTypes from "prop-types"
import EnsLabel from "../EnsLabel/EnsLabel"
import EnsLink from "../EnsLink/EnsLink"
import "./ArticleItem.scss"
import { GatsbyImage } from "gatsby-plugin-image"

const ArticleItem = ({ tags, title, categories, date, link,  externalLink, image }) => {
  return (
    <article className="article-item">
     {title && (
        <EnsLink link={{ url: externalLink ?  externalLink : link}}>
      {image && (
        <div className="article-image">
          <div className="article-tags">
          {tags &&
            tags.map(tag => (
              <EnsLabel variant={tag.name?.toLowerCase()} key={tag.id} className="tag-item">
                {tag.name}
              </EnsLabel>
            ))}
          </div>
            <GatsbyImage image={image?.localFile?.childImageSharp?.gatsbyImageData} 
                             alt={image?.altText} 
                             className="article-image-container"
                             loading="lazy" />
        </div>
      )}

          <p className="article-title h4">{title}</p>
      <div>
        {categories &&
          categories.map(cat => (
            <EnsLink link={{ url: cat.link }} key={cat.id}>
              <span key={cat.id} className="article-category">
                {cat.name}
              </span>
            </EnsLink>
          ))}
        {date && <span className="article-date"> {date}</span>}
      </div>
      </EnsLink>
      )}
    </article>
  )
}

ArticleItem.defaultProps = {
  categories: [],
  tags: [],
}

ArticleItem.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  categories: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
  ),
  date: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
export default ArticleItem
